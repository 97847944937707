<template>
   <section class="testimonial-one">
      <div id="testimonialBg" class="testimonial-shape wow slideInRight" data-wow-delay="100ms" data-wow-duration="2500ms">
      </div>
      <div class="container">
          <div class="section-title text-left">
              <span class="section-title__tagline">{{ $store.state.testimonials.tagLine }}</span>
              <h2 class="section-title__title">{{ $store.state.testimonials.title }}</h2>
          </div>
          <div class="row">
              <div class="col-xl-12">
                  <div class="testimonial-one__inner">
                      <div class="owl-carousel owl-theme thm-owl__carousel testimonial-one__carousel"
                          data-owl-options='{
                          "loop": true,
                          "autoplay": true,
                          "margin": 30,
                          "nav": true,
                          "dots": false,
                          "smartSpeed": 500,
                          "autoplayTimeout": 10000,
                          "navText": ["<span class=\"fa fa-angle-left\"></span>","<span class=\"fa fa-angle-right\"></span>"],
                          "responsive": {
                              "0": {
                                  "items": 1
                              },
                              "768": {
                                  "items": 1
                              },
                              "992": {
                                  "items": 1
                              },
                              "1200": {
                                  "items": 1
                              }
                          }
                      }'>
                          <div class="testimonial-one__single" v-for="testimonial in $store.state.testimonials.customerTestimonial" :key="testimonial">
                              <p class="testimonial-one__text">{{testimonial.text}}</p>
                              <div class="testimonial-one__client-details">
                                  <h3 class="testimonial-one__client-name">{{testimonial.ceo}}</h3>
                                  <p class="testimonial-one__client-sub-title">{{testimonial.company}}</p>
                              </div>
                              <div class="testimonial-one__client-img">
                                  <img :src="testimonial.logo" :alt="testimonial.company">
                                  <div class="testimonial-one__client-img-boxs"></div>
                              </div>
                              <div class="testimonial-one__quote">
                                  <span class="icon-quote"></span>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
</template>


<script>


export default {
  name: 'TestimonialContent',
}
</script>

<style scoped></style>



