<template>
    <div class="main-header-two__top">
        <div class="container">
            <div class="main-header-two__top-inner">
                <div class="main-header-two__top-address">
                    <ul class="list-unstyled main-header-two__top-address-list">
                        <li>
                            <div class="icon">
                                <span class="fa fa-map-marker-alt"></span>
                            </div>
                            <div class="text">
                                <p>{{ $store.state.company.adrress}}</p>
                            </div>
                        </li>
                        <li>
                            <div class="icon">
                                <span class="fa fa-envelope"></span>
                            </div>
                            <div class="text">
                                <p><a href="mailto:{{ $store.state.company.email}}">{{ $store.state.company.email}}</a></p>
                            </div>
                        </li>
                        <li>
                            <div class="icon">
                                <span class="fa fa-clock"></span>
                            </div>
                            <div class="text">
                                <p>{{ $store.state.company.openingHours}}</p>
                            </div>
                        </li>
                    </ul>
                </div>
              <div class="main-header-two__top-social">
                <router-link title="Revendedor" to="revendedor" id="btn-os"><i class="fa fa-edit"></i> Revendedor</router-link>
              </div>
                <div class="main-header-two__top-social">
                  <router-link title="Consultar OS" to="consultar-os"><i class="fa fa-edit"></i> Consultar OS</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TopHeader',
}
</script>

<style scoped>
#btn-os{
  margin-right: 2px;
}
</style>
