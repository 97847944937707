<template>
  <section class="contact-one">
    <div class="contact-one-shape-4 wow slideInLeft" data-wow-delay="100ms" data-wow-duration="2500ms">
      <img src="@/assets/images/shapes/contact-one-shape-4.png" alt="">
    </div>
    <div class="contact-one__container">
      <div class="container">
        <div class="row">
          <div class="col-xl-7 col-lg-7">
            <div class="contact-one__left">
              <div class="section-title text-left">
                <span class="section-title__tagline">{{ $store.state.ContactUs.tagLine }}</span>
                <h2 class="section-title__title">{{ $store.state.ContactUs.title }}</h2>
              </div>
              <form @submit.prevent="submitForm" class="contact-one__form contact-form-validated"
                    novalidate="novalidate">
                <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12">
                    <div class="contact-one__form-input-box"><input type="text" placeholder="Nome completo" name="nome"
                                                                    v-model="nome"></div>
                    <span v-if="errors.nome" class="error">{{ errors.nome }}</span>
                  </div>
                  <div class="col-xl-12 col-lg-12 col-md-12">
                    <div class="contact-one__form-input-box"><input type="email" placeholder="seu email" name="email"
                                                                    v-model="email"></div>
                    <span v-if="errors.email" class="error">{{ errors.email }}</span>
                  </div>
                  <div class="col-xl-12 col-lg-12 col-md-12">
                    <div class="contact-one__form-input-box"><input type="text" placeholder="(85) 9999-9999" name="fone"
                                                                    v-model="fone"></div>
                    <span v-if="errors.fone" class="error">{{ errors.fone }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="contact-one__form-input-box text-message-box">
                      <textarea name="message" placeholder="sua mensagem..."></textarea>
                    </div>
                    <div class="contact-one__btn-box">
                      <button type="submit" class="thm-btn contact-one__btn">Enviar <i
                          class="fa fa-angle-right"></i></button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="col-xl-5 col-lg-5">
            <div class="contact-one__right">
              <div class="contact-one__img">
                <img src="@/assets/images/resources/contact-one-img-1.jpg" alt="">
              </div>
              <div class="contact-one__call">
                <div class="contact-one__call-icon">
                  <span class="icon-phone-call"></span>
                </div>
                <div class="contact-one__call-content">
                  <p class="contact-one__call-sub-title">Solicite um orçamento</p>
                  <h5 class="contact-one__call-number">
                    <a href="tel:8532315237">{{  }}</a>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script>


export default {
  name: 'ContactContent',
  data() {
    return {
      nome: '',
      email: '',
      fone: '',
      errors: {}
    };
  },
  computed: {
    isFormInvalid() {
      return this.errors.token || this.errors.password;
    }
  },
  methods: {
    submitForm() {
      this.errors = {};

      if (!this.email) {
        this.errors.email = 'O email é obrigatório.';
      }

      if (!this.nome) {
        this.errors.nome = 'O nome é obrigatório.';
      }

      if (!this.fone) {
        this.errors.fone = 'O Telefone é obrigatório.';
      }

      if (Object.keys(this.errors).length === 0) {
        alert('cadastro enviado com sucesso!!!!');
        this.clearInvoiceData();
      }
    },
    clearInvoiceData() {
      this.nome = '';
      this.cnpj = '';
      this.fone = '';
      this.errors = {};
    },
  }
}
</script>

<style scoped>
.error{
  color: #FFFFFF;
}
</style>



