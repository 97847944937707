import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ProductView from "@/views/ProductView.vue";
import ServiceView from "@/views/ServiceView.vue";
import ContactView from "@/views/ContactView.vue";
import ResaleView from "@/views/ResaleView.vue";
import AboutUsView from "@/views/AboutUsView.vue";
import ContactResaleView from "@/views/ContactResaleView.vue";
import ConsultView from "@/views/ConsultView.vue";

const routes = [
    {
        path: '/',
        name: '/',
        component: HomeView
    },
    {
        path: '/quem-somos',
        name: 'quem-somos',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        //component: () => import(/* webpackChunkName: "about" */ '../views/AboutUsView.vue')
        component: AboutUsView
    },
    {
        path: '/produtos',
        name: 'produtos',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        // component: () => import(/* webpackChunkName: "about" */ '../views/ProductView.vue')
        component: ProductView
    },
    {
        path: '/nossos-servicos',
        name: 'nossos-serviços',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        //component: () => import(/* webpackChunkName: "about" */ '../views/ServiceView.vue')
        component: ServiceView
    },
    {
        path: '/consultar-os',
        name: 'consult',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        //component: () => import(/* webpackChunkName: "about" */ '../views/ConsultView.vue')
        component: ConsultView
    },
    {
        path: '/revendedor',
        name: 'resales',
        component: ResaleView
    },
    {
        path: '/cadastro-revenda',
        name: 'resale',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        //component: () => import(/* webpackChunkName: "about" */ '../views/ContactResaleView.vue')
        component: ContactResaleView
    },
    {
        path: '/fale-conosco',
        name: 'contact',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        //component: () => import(/* webpackChunkName: "about" */ '../views/ContactView.vue')
        component: ContactView
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
