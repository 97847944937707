<template>
  <MapContent/>
  <FooterContent/>
</template>

<script>

import MapContent from "@/components/footer/MapContent.vue";
import FooterContent from "@/components/footer/FooterContent.vue";


export default {
    name: 'MainFooter',
    components: {FooterContent,  MapContent },
}
</script>

<style scoped>

</style>
