<template>
    <BannerContent/>
  <AboutUsContent/>
  <ServiceContent/>
  <CallContent/>
  <TestimonialContent/>
  <BrandContent/>
  <ContactContent/>
</template>

<script>

import BannerContent from "@/components/content/BannerContent.vue";
import AboutUsContent from "@/components/content/AboutUsContent.vue";
import ServiceContent from "@/components/content/ServiceContent.vue";
import CallContent from "@/components/content/CallContent.vue";
import TestimonialContent from "@/components/content/TestimonialContent.vue";
import BrandContent from "@/components/content/BrandContent.vue";
import ContactContent from "@/components/content/ContactContent.vue";


export default {
    name: 'MainContent',
    components: { AboutUsContent, BannerContent, ServiceContent, CallContent, TestimonialContent, BrandContent, ContactContent},
}
</script>

<style scoped>

</style>
