<template>
    <a href="#" data-target="html" class="scroll-to-target scroll-to-top"><i class="fa fa-angle-up"></i></a>
</template>

<script>
export default {
    name: 'ScrollToTop',
}
</script>

<style scoped>
/* scroll to top */
.scroll-to-top {
    display: inline-block;
    width: 45px;
    height: 45px;
    background: var(--brote-base);
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 99;
    text-align: center;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    display: none;
    border-radius: 50%;
    transition: all 0.4s ease;
}

.scroll-to-top i {
    color: #ffffff;
    font-size: 18px;
    line-height: 45px;
}

.scroll-to-top:hover {
    background-color: var(--brote-base);
}

.scroll-to-top:hover i {
    color: #fff;
}



</style>
