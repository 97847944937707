<template>
    <section class="brand-one">
        <div class="container">
            <div class="brand-one__inner">
              <div class="section-title text-center">
                <span class="section-title__tagline">{{ $store.state.brand.tagLine }}</span>
                <h2 class="section-title__title">{{ $store.state.brand.title }}</h2>
              </div>
                <div class="row">
                    <div class="col-xl-12">
                        <div class="brand-one__main-content">
                            <div class="thm-swiper__slider swiper-container" data-swiper-options='{"spaceBetween": 100, "slidesPerView": 5, "autoplay": { "delay": 5000 }, "breakpoints": {
                       "0": {
                           "spaceBetween": 30,
                           "slidesPerView": 2
                       },
                       "375": {
                           "spaceBetween": 30,
                           "slidesPerView": 2
                       },
                       "575": {
                           "spaceBetween": 30,
                           "slidesPerView": 3
                       },
                       "767": {
                           "spaceBetween": 50,
                           "slidesPerView": 4
                       },
                       "991": {
                           "spaceBetween": 50,
                           "slidesPerView": 5
                       },
                       "1199": {
                           "spaceBetween": 100,
                           "slidesPerView": 5
                       }
                   }}'>
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide" v-for="brand in $store.state.brands" :key="brand.title">
                                        <img :src="brand.img" :alt="brand.title">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script>


export default {
  name: 'BrandContent',
}
</script>

<style scoped></style>



