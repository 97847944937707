<template>
  <div>
    <MainHeader/>
    <BreadcrumbContent title="Seja um Revendedor" page-name="Seja um Revendedor"/>
    <ContactResaleContent/>
    <CallContent/>
    <BrandContent/>
    <MainFooter/>
  </div>
</template>

<script>
// @ is an alias to /src


import MainHeader from "@/components/header/MainHeader.vue";
import MainFooter from "@/components/footer/MainFooter.vue";
import BrandContent from "@/components/content/BrandContent.vue";
import CallContent from "@/components/content/CallContent.vue";
import BreadcrumbContent from "@/components/content/BreadcrumbContent.vue";
import ContactResaleContent from "@/components/content/ContactResaleContent.vue";


export default {
  name: 'ResaleView',
  components: {
    ContactResaleContent,
    BreadcrumbContent,
    CallContent, BrandContent,
    MainFooter,
    MainHeader
  }
}
</script>
