<template>
  <footer class="site-footer">
    <div class="site-footer__top">
       <div class="container">
          <div class="row">
             <div class="col-xl-6 col-lg-6 col-md-12 wow fadeInUp" data-wow-delay="100ms">
                <div class="footer-widget__column footer-widget__about">
                   <h3 class="footer-widget__title"><a href="index.html"><img src="@/assets/images/resources/information-logo.png" alt="Logos Informática"></a></h3>
                   <div class="footer-widget__about-text-box">
                      <p class="footer-widget__about-text">{{ $store.state.aboutUs.subTitle1}}</p>
                   </div>
                   <div class="site-footer__social"><a href="#"><i class="fab fa-whatsapp"></i></a><a href="#"><i class="fab fa-instagram"></i></a></div>
                </div>
             </div>
             <div class="col-xl-3 col-lg-3 col-md-12 wow fadeInUp" data-wow-delay="200ms">
                <div class="footer-widget__column footer-widget__links clearfix">
                   <h3 class="footer-widget__title">Menu</h3>
                   <ul class="footer-widget__links-list list-unstyled clearfix">
                     <li :class="menu.link === $route.name ?'current':''" v-for="menu in $store.state.menu"
                         :key="menu.text">
                       <router-link :to="menu.link">{{ menu.text }}</router-link>
                     </li>
                      <li><router-link title="Consultar OS" to="consultar-os">Consultar OS</router-link></li>
                   </ul>
                </div>
             </div>
             <div class="col-xl-3 col-lg-3 col-md-12 wow fadeInUp" data-wow-delay="300ms">
                <div class="footer-widget__column footer-widget__articles clearfix">
                   <h3 class="footer-widget__title">Onde nós estamos</h3>
                   <ul class="footer-widget__links-list list-unstyled clearfix">
                      <li><span class="fa fa-clock"></span> {{ $store.state.company.openingHours}}</li>
                      <li><span class="fa fa-map-marker-alt"></span> {{ $store.state.company.adrress}}</li>
                      <li><i class="fas fa-phone-alt"></i> {{ $store.state.company.fonesFixos}}</li>
                      <li><i class="fab fa-whatsapp"></i> {{ $store.state.company.whatsApps}}</li>
                      <li>
                        <a href="mailto:{{ $store.state.company.email}}"><span class="fa fa-envelope"></span> {{ $store.state.company.email}}</a>
                      </li>
                   </ul>
                </div>
             </div>
          </div>
       </div>
    </div>
    <div class="site-footer__bottom">
       <div class="container">
          <div class="row">
             <div class="col-xl-12">
                <div class="site-footer__bottom-inner">
                   <p class="site-footer__bottom-text">© Copyright 2023 </p>
                </div>
             </div>
          </div>
       </div>
    </div>
 </footer>
</template>


<script>


export default {
  name: 'FooterContent',
}
</script>

<style scoped></style>



