<template>
  <div class="mobile-nav__wrapper">
    <div class="mobile-nav__overlay mobile-nav__toggler"></div>
    <!-- /.mobile-nav__overlay -->
    <div class="mobile-nav__content">
      <span class="mobile-nav__close mobile-nav__toggler"><i class="fa fa-times"></i></span>

      <div class="logo-box">
        <a href="#" aria-label="logo image"><img src="@/assets/images/resources/logo-1.png" width="89"
                                                 alt=""/></a>
      </div>
      <!-- /.logo-box -->
      <div class="mobile-nav__container"></div>
      <!-- /.mobile-nav__container -->

      <ul class="mobile-nav__contact list-unstyled">
        <li>
          <i class="fa fa-envelope"></i>
          <a href="mailto:{{ $store.state.company.email}}">{{ $store.state.company.email }}</a>
        </li>
        <li>
          <i class="fa fa-phone-alt"></i>
          <a href="tel:8532315237">(85) 3231.5237 / 3222.1042
          </a>
        </li>
        <li>
          <i class="fab fa-whatsapp"></i>
          <a href="tel:8598761">(85) 98761.4228 / 99948.1012</a>
        </li>
      </ul><!-- /.mobile-nav__contact -->
<!--      <div class="mobile-nav__top">-->
<!--        <div class="mobile-nav__social">-->
<!--          <a href="#" class="fab fa-twitter"></a>-->
<!--          <a href="#" class="fab fa-facebook-square"></a>-->
<!--          <a href="#" class="fab fa-pinterest-p"></a>-->
<!--          <a href="#" class="fab fa-instagram"></a>-->
<!--        </div>&lt;!&ndash; /.mobile-nav__social &ndash;&gt;-->
<!--      </div>&lt;!&ndash; /.mobile-nav__top &ndash;&gt;-->


    </div>
    <!-- /.mobile-nav__content -->
  </div>
  <!-- /.mobile-nav__wrapper -->
</template>

<script>
export default {
  name: 'MobileNav',

}
</script>

<style scoped>
</style>
