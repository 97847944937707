<template>
  <section class="we-cleaning">
    <div class="container">
      <div class="row">
        <div class="col-xl-12" style="min-height: 400px">
          <h2> Peças p/ impressoras {{filterTitle}}</h2>
          <div v-if="loading" class="text-center">
            <i class="fa fa-spinner fa-spin"></i> Carregando...
          </div>

          <div v-else>
            <div class="text-center mb-3 mt-5">
              <button class="thm-btn home-showcase__buttons__item2 active" :class="{ 'active': filter === '294' }"
                      @click="applyFilter('294')">Jato
                de Tinta
              </button>
              <button class="thm-btn home-showcase__buttons__item2" :class="{ 'active': filter === '134' }"
                      @click="applyFilter('134')">Matricial
              </button>
              <button class="thm-btn home-showcase__buttons__item2" :class="{ 'active': filter === '296' }" @click="applyFilter('296')">
                Laser
              </button>
            </div>

            <table class="table">
              <thead>
              <tr>
                <th scope="col">Descrição</th>
                <th scope="col">Promoção</th>
                <th scope="col">Valor Final</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="product in products" :key="product.descricao">
                <td>{{ product.descricao }}</td>
                <td>{{ product.valorpromocao == '0,00' ? "-" : product.valorpromocao }}</td>
                <td>{{ product.valorunitario }}</td>
                <td>{{ product.figura }}</td>
              </tr>
              </tbody>
            </table>

            <div class="mt-3 text-center">
              <div>Mostrando: {{ page }}/{{ totalPages }}</div>
              <div></div>

              <button class="btn btn-primary" @click="previousPage" :disabled="page === 1">Anterior</button>
              <button class="btn btn-primary" @click="nextPage" :disabled="page === totalPages">Próximo</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>


import axios from "axios";

export default {
  name: 'ProductContent',
  data() {
    return {
      products: [],
      page: 1,
      totalPages: 0,
      loading: false,
      filter: '294',
      filterTitle:"Jato de tinta"
    };
  },
  mounted() {
    this.fetchProducts();
  },
  methods: {
    fetchProducts() {
      this.loading = true;
      const url = 'https://logosfor.com.br/api/produtos.php?page=' + this.page + '&filter=' + this.filter;
      axios.get(url)
          .then(response => {
            this.products = response.data.products;
            this.totalPages = Math.ceil(response.data.total_products / 20);
          })
          .catch(error => {
            console.error(error);
          }).finally(() => {
        this.loading = false;
      });
    },
    previousPage() {
      if (this.page > 1) {
        this.page--;
        this.fetchProducts();
      }
    },
    nextPage() {
      if (this.page < this.totalPages) {
        this.page++;
        this.fetchProducts();
      }
    },
    applyFilter(filter) {
      this.filter = filter; // Set the selected filter
      if(filter=="294"){
        this.filterTitle = "jato de tinta";
      }else if(filter=="134"){
        this.filterTitle = "matricial";
      }else if(filter=="296"){
        this.filterTitle = "laser";
      }else{
        this.filterTitle = "jato de tinta";
      }

      this.page = 1; // Reset the page to 1 when applying a filter
      this.fetchProducts(); // Fetch products with the selected filter
    },
  }
}
</script>

<style scoped></style>



