<template>
  <section class="we-cleaning">
    <div class="container">
      <div class="row">
        <div class="col-xl-6">
          <div class="we-cleaning__left">
            <div class="we-cleaning__img-box wow slideInLeft" data-wow-delay="100ms" data-wow-duration="2500ms">
              <div class="we-cleaning__img">
                <img src="@/assets/images/resources/we-cleaning-img-1.jpg" alt="">
              </div>
              <div class="we-cleaning-line">
                <img src="@/assets/images/shapes/we-cleaning-line.png" alt="">
              </div>
              <div class="we-cleaning__small-img">
                <img src="@/assets/images/resources/we-cleaning-small-img.jpg" alt="">
              </div>
              <div class="we-cleaning__shape-1"></div>
              <div class="we-cleaning__shape-3"></div>
              <div class="we-cleaning__shape-4"></div>
              <div class="we-cleaning__shape-5"></div>
            </div>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="we-cleaning__right">
            <div class="section-title text-left">
              <span class="section-title__tagline">{{ $store.state.aboutUs.tagLine }}</span>
              <h2 class="section-title__title">{{ $store.state.aboutUs.title }}</h2>
            </div>
            <p class="welcome-one__text-1">{{ $store.state.aboutUs.subTitle1 }}</p>
            <p class="welcome-one__text-2">{{ $store.state.aboutUs.subTitle2 }}</p>
            <div class="welcome-one__points-box">
              <ul class="list-unstyled welcome-one__points">

                <li v-for="service in $store.state.aboutUs.services1" :key="service">
                  <div class="icon">
                    <span class="icon-tick"></span>
                  </div>
                  <div class="text">
                    <p>{{ service }}</p>
                  </div>
                </li>
              </ul>
              <ul class="list-unstyled welcome-one__points welcome-one__points-two">
                <li v-for="service in $store.state.aboutUs.services2" :key="service">
                  <div class="icon">
                    <span class="icon-tick"></span>
                  </div>
                  <div class="text">
                    <p>{{ service }}</p>
                  </div>
                </li>
              </ul>
            </div>
            <div class="welcome-one__btn-call-box">
              <div class="welcome-one__btn-box">
                <a href="{{ $store.state.aboutUs.button.link }}" class="thm-btn welcome-one__btn">{{
                  $store.state.aboutUs.button.text }} <i class="fa fa-angle-right"></i></a>
              </div>
              <div class="welcome-one__call-box">
                <div class="welcome-one__call-icon">
                  <span class="icon-phone-call"></span>
                </div>
                <div class="welcome-one__call-content">
                  <p class="welcome-one__call-sub-title">{{$store.state.aboutUs.button2.text }}</p>
                  <h5 class="welcome-one__call-number"><a href="tel:+558532315237">{{ $store.state.aboutUs.button2.subText }}</a></h5>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </section>
</template>


<script>


export default {
  name: 'AboutUsContent',
}
</script>

<style scoped></style>



