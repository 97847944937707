<template>
  <section class="page-header">
    <div class="page-header-bg"></div>
    <div class="container">
      <div class="page-header__inner">
        <ul class="thm-breadcrumb list-unstyled">
          <li>
            <router-link to="/">Home</router-link>
          </li>
          <li><span>/</span></li>
          <li>{{ pageName }}</li>
        </ul>
        <h2>{{ title }}</h2>
      </div>
    </div>
  </section>
</template>


<script>


export default {
  name: 'BreadcrumbContent',
  props: {
    pageName: String,
    title: String
  }
}
</script>

<style scoped></style>



