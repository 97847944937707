<template>
  <section class="get-to-know">
    <div class="get-to-know-bubble float-bob-x">
      <img src="@/assets/images/shapes/get-to-know-bubble.png" alt="">
    </div>
    <div class="container">
      <div class="row">
        <div class="col-xl-6">
          <div class="get-to-know__left">
            <div class="get-to-know__img wow slideInLeft" data-wow-delay="100ms"
                 data-wow-duration="2500ms">
              <img src="@/assets/images/resources/we-cleaning-img-1.jpg" alt="{{ $store.state.company.name }}">
              <div class="get-to-know-shape-1"></div>
              <div class="get-to-know-shape-2"></div>
            </div>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="get-to-know__right">
            <div class="section-title text-left">
              <span class="section-title__tagline">{{ $store.state.aboutUs.tagLine }}</span>
              <h2 class="section-title__title">{{ $store.state.aboutUs.title }}</h2>
            </div>
            <p class="welcome-one__text-1">{{ $store.state.aboutUs.subTitle1 }}</p>
            <p class="welcome-one__text-2">{{ $store.state.aboutUs.subTitle2 }}</p>
            <div class="get-to-know__points-box">
              <ul class="list-unstyled get-to-know__points">
                <li v-for="service in $store.state.aboutUs.services1" :key="service">
                  <div class="icon">
                    <span class="icon-tick"></span>
                  </div>
                  <div class="text">
                    <p>{{ service }}</p>
                  </div>
                </li>
              </ul>
              <ul class="list-unstyled get-to-know__points get-to-know__points--two">
                <li v-for="service in $store.state.aboutUs.services2" :key="service">
                  <div class="icon">
                    <span class="icon-tick"></span>
                  </div>
                  <div class="text">
                    <p>{{ service }}</p>
                  </div>
                </li>
              </ul>
            </div>
            <div class="welcome-one__btn-call-box">
              <div class="welcome-one__btn-box">
                <a href="{{ $store.state.aboutUs.button.link }}" class="thm-btn welcome-one__btn">{{
                    $store.state.aboutUs.button.text }} <i class="fa fa-angle-right"></i></a>
              </div>
              <div class="welcome-one__call-box">
                <div class="welcome-one__call-icon">
                  <span class="icon-phone-call"></span>
                </div>
                <div class="welcome-one__call-content">
                  <p class="welcome-one__call-sub-title">{{$store.state.aboutUs.button2.text }}</p>
                  <h5 class="welcome-one__call-number"><a href="tel:+558532315237">{{ $store.state.aboutUs.button2.subText }}</a></h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script>


export default {
  name: 'OurStoryContent',
}
</script>

<style scoped>

</style>



