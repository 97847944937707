<template>
  <section class="message-one">
    <div class="container">
      <div class="section-title text-center">
        <span class="section-title__tagline">Verifique o status do serviço</span>
        <h2 class="section-title__title">Consulta de OS</h2>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <div class="contact-page-two__right">
            <div v-if="invoiceData !== null" id="print-os">
              <div class="card">
                <div class="card-body p-0">
                  <div class="row p-5">
                    <div class="col-md-12 mb-5">
                      <h4 class="text-center">Ordem de serviço</h4>
                    </div>

                    <div class="col-md-6">
                      <img
                          :src="invoiceData.emp_codigo==1171 ? require('@/assets/logo.png'): require('@/assets/images/brand/servmaq.png')"
                          alt="" width="100">
                    </div>

                    <div class="col-md-6 text-end">
                      <p class="font-weight-bold mb-1">Orçamento nº #{{ invoiceData.solicitacao }}</p>
                      <p class="font-weight-bold mb-1">Solicitação nº #{{ invoiceData.solicitacao }}</p>
                      <p class="text-muted">Data: {{ invoiceData.datadasolicitacao }}</p>
                      <p class="text-muted">Status: {{ invoiceData.sta_descricao }}</p>
                    </div>

                    <div class="row p-3 mt-5" style="background-color: #f7f7f7">
                      <h5 class="font-weight-bold ">Dados da Empresa</h5>
                      <hr>
                      <div class="col-md-6">
                        <p class="mb-1"><strong>Empresa: </strong>
                          {{ invoiceData.emp_codigo == 1171 ? "LOGOS COMERCIAL E TECNICA LTDA" : "SERVMAQ LTDA" }}</p>
                        <p class="mb-1"><strong>End: </strong> {{ invoiceData.emp_end }}</p>
                        <p class="mb-1"><strong>Fortaleza-CE: </strong></p>
                      </div>
                      <div class="col-md-6">
                        <p class="mb-1"><strong>CNPJ: </strong>
                          {{ invoiceData.emp_codigo == 1171 ? "86.906.336/0001-33" : "00.716.300/0001-71" }}</p>
                        <p class="mb-1"><strong>IE: </strong> {{ invoiceData.emp_ie }}</p>
                        <p class="mb-1"><strong>Contato: </strong> {{ invoiceData.emp_fone }}</p>
                      </div>

                    </div>

                    <div class="row p-3 mt-1" style="background-color: #f7f7f7">
                      <h5 class="font-weight-bold ">Dados do Cliente</h5>
                      <hr>
                      <div class="col-md-5">
                        <p class="mb-1"><strong>Nome: </strong>
                          {{ invoiceData.cli_nome }}
                        </p>
                        <p class="mb-1"><strong>End: </strong> {{ invoiceData.cli_end }}</p>
                        <p class="mb-1"><strong>Fortaleza-CE: </strong></p>
                      </div>
                      <div class="col-md-3">
                        <p class="mb-1"><strong>CNPJ: </strong>
                          {{ invoiceData.cli_cnpj }}
                        </p>
                        <p class="mb-1"><strong>IE: </strong> {{ invoiceData.cli_ie }}</p>
                        <p class="mb-1"><strong>IM: </strong> {{ invoiceData.cli_im }}</p>
                      </div>

                      <div class="col-md-4">
                        <p class="mb-1"><strong>Solicitante: </strong>
                          {{ invoiceData.cli_solicitante }}
                        </p>
                        <p class="mb-1"><strong>Contato: </strong> {{ invoiceData.cli_fone }}</p>
                      </div>
                    </div>
                  </div>

                  <div class="row p-5">
                    <div class="col-md-12">
                      <table class="table">
                        <thead>
                        <tr>
                          <th class="border-0 text-uppercase small font-weight-bold">#</th>
                          <th class="border-0 text-uppercase small font-weight-bold">Equipamento</th>
                          <th class="border-0 text-uppercase small font-weight-bold">Nº Série</th>
                          <th class="border-0 text-uppercase small font-weight-bold">Patrimonio</th>
                          <th class="border-0 text-uppercase small font-weight-bold">Setor</th>
                          <th class="border-0 text-uppercase small font-weight-bold">Usuário</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td>{{ invoiceData.mat_codigo }}</td>
                          <td>{{ invoiceData.mat_descricao }}</td>
                          <td>{{ invoiceData.mat_serie }}</td>
                          <td>{{ invoiceData.mat_patrimonio }}</td>
                          <td>{{ invoiceData.mat_setor }}</td>
                          <td>{{ invoiceData.mat_usuario }}</td>
                        </tr>
                        <tr>
                          <td colspan="6">
                            <tr>
                              <td><strong>Serviços: </strong></td>
                              <td v-html="invoiceData.servicos"></td>
                            </tr>
                            <tr>
                              <td><strong>Substituições: </strong></td>
                              <td v-html="invoiceData.substituicoes"></td>
                            </tr>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div class="col-12 bg-dark text-white p-4">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="h4 font-weight-light">Gastos Orçados</div>
                        <hr>
                        <p class="mb-1"><strong>Serviços: </strong>
                          {{ invoiceData.valordoservico }}
                        </p>
                        <p class="mb-1"><strong>Peças: </strong>
                          {{ invoiceData.valordaspecas }}
                        </p>
                        <p class="mb-1"><strong>Desconto: </strong>
                          {{ invoiceData.valordodesconto }}
                        </p>
                        <p class="mb-1"><strong>total: </strong>
                          {{ invoiceData.total }}
                        </p>
                      </div>

                      <div class="col-md-6">
                        <div class="h4 font-weight-light">Prazos</div>
                        <hr>
                        <p class="mb-1"><strong>Validade Orçamento: </strong>
                          {{ invoiceData.datadevencimento }}
                        </p>
                        <p class="mb-1"><strong>Data Orçamento: </strong>
                          {{ invoiceData.datadoorcamento }}
                        </p>
                        <p class="mb-1"><strong>Garantia em dias: </strong>
                          {{ invoiceData.garatia }}
                        </p>
                        <p class="mb-1"><strong>Validade Garantia: </strong>
                          {{ invoiceData.datadevalidade }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="text-center mb-3 mt-5 no-print">
                <button class="btn btn-success mx-1 no-print"
                        @click="printInvoice"><i class="fa fa-print"></i> Imprimir
                </button>
                <button class="btn btn-primary mx-1 no-print"
                        @click="authorizeBudget" v-if="invoiceData.sta_codigo==4"><i class="fa fa-check"></i> Aprovar
                  Orçamento
                </button>
                <button class="btn btn-dark mx-1 no-print"
                        @click="clearInvoiceData"><i class="fa fa-window-close"></i> Sair
                </button>
              </div>
            </div>
            <div v-else-if="isLoading">
              <p class="text-center">Carregando...</p>
            </div>
            <div v-else>

              <div class="comment-form__btn-box" v-if="showNotFoundMessage">
                <p class="text-center">Dados não encontrados.</p>
                <button type="button" @click="clearInvoiceData" class="thm-btn comment-form__btn">Realizar nova consulta<i
                    class="fa fa-angle-right"></i></button>
              </div>

              <form v-else class="comment-one__form contact-form-validated" novalidate="novalidate"
                    @submit.prevent="submitForm">
                <div class="row">
                  <div class="col-xl-6">
                    <label for="token">Solicitação Técnica</label>
                    <div class="comment-form__input-box">
                      <input type="text" placeholder="informe o numero da ST" name="token" id="token" v-model="token">
                      <span v-if="errors.token" class="error">{{ errors.token }}</span>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <label for="password">Senha</label>
                    <div class="comment-form__input-box">
                      <input type="password" placeholder="*********" name="password" id="password" v-model="password">
                      <span v-if="errors.password" class="error">{{ errors.password }}</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="comment-form__btn-box">
                      <button type="submit" :disabled="isFormInvalid" class="thm-btn comment-form__btn">Consultar OS<i
                          class="fa fa-angle-right"></i></button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import printJS from 'print-js'

export default {
  name: "OsContent",
  data() {
    return {
      invoiceData: null,
      isLoading: false,
      showNotFoundMessage: false,
      token: '',
      password: '',
      errors: {}
    };
  },
  computed: {
    isFormInvalid() {
      return this.errors.token || this.errors.password;
    }
  },
  methods: {
    submitForm() {
      this.errors = {};

      if (!this.token) {
        this.errors.token = 'O campo solicitação ténica é obrigatório.';
      }

      if (!this.password) {
        this.errors.password = 'O campo Senha é obrigatório.';
      }

      if (Object.keys(this.errors).length === 0) {
        this.fetchInvoiceData();
      }
    },
    fetchInvoiceData() {
      this.isLoading = true;
      this.showNotFoundMessage = false;
      const formData = new FormData();
      formData.append('token', this.token);
      formData.append('password', this.password);

      axios.post('https://logosfor.com.br/api/orcamento.php', formData)
          .then(response => {
            if (response.data != null) {
              this.invoiceData = response.data;
            } else {
              this.invoiceData = null;
              this.showNotFoundMessage = true;
            }
          })
          .catch(error => {
            console.error(error);
            this.invoiceData = null;
          })
          .finally(() => {
                this.isLoading = false;
              }
          );
    },
    printInvoice() {
      printJS({
        printable: 'print-os',
        type: 'html',
        css: [
          'https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap',
          require('@/assets/css/bootstrap.min.css'),
          require('@/assets/css/brote.css'),
        ],
        scanStyles: false,
        targetStyles: ['*']
      })
      console.log('Imprimindo fatura...');

    },
    clearInvoiceData() {
      this.invoiceData = null;
      this.token = '';
      this.password = '';
      this.errors = {};
      this.showNotFoundMessage = false;
    },
    authorizeBudget() {
      const formData = new FormData();
      formData.append("token", this.token);
      formData.append("password", this.password);

      axios
          .post("https://logosfor.com.br/api/aprovar_orcamento.php", formData)
          .then((response) => {
            if (response.data.success) {
              this.invoiceData.sta_codigo = 7;
              this.invoiceData.sta_descricao = 'Autorizado';
              alert('Ordem de serviço autorizada com sucesso!!!');
            }
            console.log(response.data); // Verificar e processar a resposta da API conforme necessário
          })
          .catch((error) => {
            console.error(error);
            // Exibir mensagem de erro ou realizar outras ações necessárias
          });
    },
  }
};
</script>


<style scoped>

</style>