<template>
  <section class="contact-page-one-details">
    <div class="container">
      <ul class="list-unstyled contact-page-one-details__list">
        <li>
          <div class="contact-page-one-details__icon"><span class="icon-phone-call"
          ></span></div>
          <div class="contact-page-one-details__content-box"><p
              class="contact-page-one-details__content"><a href="tel:2300068603">(85)
            3231.5237 / (85) 3222.1042</a><a href="tel:6668888000">(85) 98761.4228 / (85)
            99948.1012</a></p></div>
        </li>
        <li>
          <div class="contact-page-one-details__icon"><span class="icon-message"
          ></span></div>
          <div class="contact-page-one-details__content-box">
            <p class="contact-page-one-details__content"><a
                href="mailto:{{ $store.state.company.email}}">{{ $store.state.company.email }}</a>
            </p>
          </div>
        </li>
        <li>
          <div class="contact-page-one-details__icon"><span class="icon-location"
          ></span></div>
          <div class="contact-page-one-details__content-box"><h5
              class="contact-page-one-details__address"> Rua Barão de Aratanha, 194 - Centro <br
          > Fortaleza-CE </h5></div>
        </li>
      </ul>
    </div>
  </section>
</template>


<script>


export default {
  name: 'ContactInfoContent',
}
</script>

<style scoped></style>



