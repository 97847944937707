<template>
  <section class="call-one">
    <div class="container">
      <div class="call-one__inner">
        <div class="col-lg-9 col-md-12 col-xs-12 col-sm-12">
          <div class="call-one__text-box">
            <h3 class="call-one__text">SUA IMPRESSORA QUEBROU? A <b id="logos">Logos Informática</b> resolve!</h3>
            <p class="call-one__sub_text"> Ligue para o nós ou fale com a gente pelo nosso Whatsapp, e faremos o melhor
              orçamento para você em até 72hs.</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-12 col-xs-12 col-sm-12">
          <div class="call-one__call-number">
            <a href="tel:32315237">
              <i class="fas fa-phone-alt"></i> {{ $store.state.company.foneFixo1 }} / <br>
              {{ $store.state.company.foneFixo2 }}</a></div>
          <br>
          <div class="call-one__call-number"><a
              href="https://api.whatsapp.com/send?phone=5585987614228&amp;text=Ol%C3%A1,%20gostaria%20de%20solicitar%20um%20or%C3%A7amento."
              target="_blank"><i class="fab fa-whatsapp"></i> {{ $store.state.company.whatsApp1 }} /
            <br>{{ $store.state.company.whatsApp2 }}</a></div>
        </div>
      </div>
    </div>
  </section>
</template>


<script>


export default {
  name: 'CallContent',
}
</script>

<style scoped></style>



