<template>
    <section class="google-map">
        <iframe class="google-map__one" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d248.8340921003981!2d-38.52696830076048!3d-3.734673427889474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7c7485530626b41%3A0x5cdf7300beb6b08b!2sLogos%20Comercial%20e%20T%C3%A9cnica!5e0!3m2!1spt-BR!2sbr!4v1682705329874!5m2!1spt-BR!2sbr" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" style="border: 0px;"></iframe>
    </section>
</template>


<script>


export default {
  name: 'MapContent',
}
</script>

<style scoped></style>



