<template>
  <div>
    <MainHeader/>
    <BreadcrumbContent title="Ordem de Serviço" page-name="Ordem de Serviço"/>
    <OsContent/>
    <CallContent/>
    <BrandContent/>
    <MainFooter/>
  </div>
</template>

<script>
// @ is an alias to /src


import MainHeader from "@/components/header/MainHeader.vue";
import MainFooter from "@/components/footer/MainFooter.vue";
import BrandContent from "@/components/content/BrandContent.vue";
import CallContent from "@/components/content/CallContent.vue";
import BreadcrumbContent from "@/components/content/BreadcrumbContent.vue";
import OsContent from "@/components/content/OsContent.vue";


export default {
  name: 'ConsultView',
  components: {
    OsContent,
    BreadcrumbContent,
    CallContent, BrandContent,
    MainFooter,
    MainHeader
  }
}
</script>
