<template>
  <div>
    <MainHeader/>
    <MainContent/>
    <MainFooter/>
  </div>
</template>

<script>
// @ is an alias to /src


import MainHeader from "@/components/header/MainHeader.vue";
import MainContent from "@/components/content/MainContent.vue";
import MainFooter from "@/components/footer/MainFooter.vue";

export default {
  name: 'HomeView',
  components: {
    MainFooter,
    MainContent,
      MainHeader

  }
}
</script>
