<template>
  <section class="message-one">
    <div class="container">
      <div class="section-title text-center">
        <h2 class="section-title__title">Revenda</h2>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <div class="contact-page-two__right">
            <div v-if="resaleAuth">
              <div class="text-center mb-3 mt-5">
                <button class="thm-btn home-showcase__buttons__item2 active" :class="{ 'active': filter === '294' }"
                        @click="applyFilter('294')">Jato
                  de Tinta
                </button>
                <button class="thm-btn home-showcase__buttons__item2" :class="{ 'active': filter === '134' }"
                        @click="applyFilter('134')">Matricial
                </button>
                <button class="thm-btn home-showcase__buttons__item2" :class="{ 'active': filter === '296' }" @click="applyFilter('296')">
                  Laser
                </button>
              </div>

              <table class="table">
                <thead>
                <tr>
                  <th scope="col">Descrição</th>
                  <th scope="col">Promoção</th>
                  <th scope="col">Valor Final</th>
                  <th scope="col">Valor Revenda</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="product in products" :key="product.descricao">
                  <td>{{ product.descricao }}</td>
                  <td>{{ product.valorpromocao == '0,00' ? "-" : product.valorpromocao }}</td>
                  <td>{{ product.valorunitario }}</td>
                  <td>{{ product.valorrevenda }}</td>
                  <td>{{ product.figura }}</td>
                </tr>
                </tbody>
              </table>

              <div class="mt-3 text-center">
                <div>Mostrando: {{ page }}/{{ totalPages }}</div>
                <div></div>

                <button class="btn btn-primary" @click="previousPage" :disabled="page === 1">Anterior</button>
                <button class="btn btn-primary" @click="nextPage" :disabled="page === totalPages">Próximo</button>
              </div>
            </div>


            <div v-else-if="isLoading">
              <p class="text-center"><i class="fa fa-spinner fa-spin"></i> Carregando...</p>
            </div>
            <div v-else>

              <div class="comment-form__btn-box" v-if="showNotFoundMessage">
                <p class="text-center">Dados não encontrados.</p>
                <button type="button" @click="clearInvoiceData" class="thm-btn comment-form__btn">Realizar nova consulta<i
                    class="fa fa-angle-right"></i></button>
              </div>

              <form v-else class="comment-one__form contact-form-validated" novalidate="novalidate"
                    @submit.prevent="submitForm">
                <div class="row">
                  <div class="col-xl-6">
                    <label for="token">Login</label>
                    <div class="comment-form__input-box">
                      <input type="text" placeholder="informe o seu login" name="token" id="token" v-model="token">
                      <span v-if="errors.token" class="error">{{ errors.token }}</span>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <label for="password">Senha</label>
                    <div class="comment-form__input-box">
                      <input type="password" placeholder="*********" name="password" id="password" v-model="password">
                      <span v-if="errors.password" class="error">{{ errors.password }}</span>
                    </div>
                  </div>
                </div>
                <div class="row align-items-end">
                  <div class="col-xl-6">
                    <div class="comment-form__btn-box ">
                      <p>Caso não possua cadastro.     <router-link to="/cadastro-revenda">clique aqui</router-link> para se cadastrar.</p>
                    </div>
                  </div>

                  <div class="col-xl-6  ">
                    <div class="comment-form__btn-box align-content-end">
                      <button type="submit" :disabled="isFormInvalid" class="thm-btn comment-form__btn">Realizar login<i
                          class="fa fa-angle-right"></i></button>
                    </div>
                  </div>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import printJS from 'print-js'

export default {
  name: "ResaleAuthProductContent",
  data() {
    return {
      resaleAuth: false,
      isLoading: false,
      showNotFoundMessage: false,
      token: '',
      password: '',
      errors: {},
      products: [],
      page: 1,
      totalPages: 0,
      loading: false,
      filter: '294',
      filterTitle:"Jato de tinta"
    };
  },
  computed: {
    isFormInvalid() {
      return this.errors.token || this.errors.password;
    }
  },
  methods: {
    submitForm() {
      this.errors = {};

      if (!this.token) {
        this.errors.token = 'O campo login é obrigatório.';
      }

      if (!this.password) {
        this.errors.password = 'O campo Senha é obrigatório.';
      }

      if (Object.keys(this.errors).length === 0) {
        this.fetchResaleData();
      }
    },
    fetchResaleData() {
      this.isLoading = true;
      this.showNotFoundMessage = false;
      const formData = new FormData();
      formData.append('token', this.token);
      formData.append('password', this.password);

      axios.post('https://logosfor.com.br/api/revenda-auth.php', formData)
          .then(response => {
            if (response.data != null) {
              this.resaleAuth = true;
              this.fetchProducts();
            } else {
              this.resaleAuth = false;
              this.showNotFoundMessage = true;
            }
          })
          .catch(error => {
            console.error(error);
            this.invoiceData = false;
          })
          .finally(() => {
                this.isLoading = false;
              }
          );
    },
    printInvoice() {
      printJS({
        printable: 'print-os',
        type: 'html',
        css: [
          'https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap',
          require('@/assets/css/bootstrap.min.css'),
          require('@/assets/css/brote.css'),
        ],
        scanStyles: false,
        targetStyles: ['*']
      })
      console.log('Imprimindo fatura...');

    },
    clearInvoiceData() {
      this.invoiceData = null;
      this.token = '';
      this.password = '';
      this.errors = {};
      this.showNotFoundMessage = false;
    },
    authorizeBudget() {
      const formData = new FormData();
      formData.append("token", this.token);
      formData.append("password", this.password);

      axios
          .post("https://logosfor.com.br/api/aprovar_orcamento.php", formData)
          .then((response) => {
            if (response.data.success) {
              this.invoiceData.sta_codigo = 7;
              this.invoiceData.sta_descricao = 'Autorizado';
              alert('Ordem de serviço autorizada com sucesso!!!');
            }
            console.log(response.data); // Verificar e processar a resposta da API conforme necessário
          })
          .catch((error) => {
            console.error(error);
            // Exibir mensagem de erro ou realizar outras ações necessárias
          });
    },
    fetchProducts() {
      this.isLoading = true;
      const url = 'https://logosfor.com.br/api/produtos2.php?page=' + this.page + '&filter=' + this.filter;
      axios.get(url)
          .then(response => {
            this.products = response.data.products;
            this.totalPages = Math.ceil(response.data.total_products / 20);
          })
          .catch(error => {
            console.error(error);
          }).finally(() => {
        this.isLoading = false;
      });
    },
    previousPage() {
      if (this.page > 1) {
        this.page--;
        this.fetchProducts();
      }
    },
    nextPage() {
      if (this.page < this.totalPages) {
        this.page++;
        this.fetchProducts();
      }
    },
    applyFilter(filter) {
      this.filter = filter; // Set the selected filter
      if(filter=="294"){
        this.filterTitle = "jato de tinta";
      }else if(filter=="134"){
        this.filterTitle = "matricial";
      }else if(filter=="296"){
        this.filterTitle = "laser";
      }else{
        this.filterTitle = "jato de tinta";
      }

      this.page = 1; // Reset the page to 1 when applying a filter
      this.fetchProducts(); // Fetch products with the selected filter
    },
  }
};
</script>


<style scoped>

</style>