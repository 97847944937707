<template>
    <header class="main-header-two clearfix">
        <TopHeader />
        <ManuHeader />
      <div class="stricky-header stricked-menu main-menu main-menu-two">
        <div class="sticky-header__content"></div><!-- /.sticky-header__content -->
      </div><!-- /.stricky-header -->
    </header>
</template>

<script>
import TopHeader from "@/components/header/TopHeader.vue";
import ManuHeader from "@/components/header/MenuHeader.vue";

export default {
    name: 'MainHeader',
    components: { ManuHeader, TopHeader },
}
</script>

<style scoped>

</style>
