<template>
  <div>
    <MainHeader/>
    <BreadcrumbContent title="Nossos Serviços" page-name="Nossos Serviços"/>
    <ServiceContent/>
    <CallContent/>
    <BrandContent/>
    <MainFooter/>
  </div>
</template>

<script>
// @ is an alias to /src


import MainHeader from "@/components/header/MainHeader.vue";
import MainFooter from "@/components/footer/MainFooter.vue";
import BrandContent from "@/components/content/BrandContent.vue";
import CallContent from "@/components/content/CallContent.vue";
import BreadcrumbContent from "@/components/content/BreadcrumbContent.vue";
import ServiceContent from "@/components/content/ServiceContent.vue";


export default {
  name: 'ServiceView',
  components: {
    ServiceContent,
    BreadcrumbContent,
    CallContent, BrandContent,
    MainFooter,
    MainHeader
  }
}
</script>
