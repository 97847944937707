<template>
  <div>
    <MainHeader/>
    <BreadcrumbContent title="Quem Somos" page-name="Quem Somos"/>
    <OurStoryContent/>
    <CallContent/>
    <BrandContent/>
    <MainFooter/>
  </div>
</template>

<script>
// @ is an alias to /src


import MainHeader from "@/components/header/MainHeader.vue";
import MainFooter from "@/components/footer/MainFooter.vue";
import BrandContent from "@/components/content/BrandContent.vue";
import CallContent from "@/components/content/CallContent.vue";
import BreadcrumbContent from "@/components/content/BreadcrumbContent.vue";
import OurStoryContent from "@/components/content/OurStoryContent.vue";


export default {
  name: 'AboutUsView',
  components: {
    OurStoryContent,
    BreadcrumbContent,
    CallContent, BrandContent,
    MainFooter,
    MainHeader
  }
}
</script>
