<template>
  <section class="services-two">
    <div class="container">
        <div class="section-title text-center">
            <span class="section-title__tagline">{{ $store.state.services.tagLine }}</span>
            <h2 class="section-title__title">{{ $store.state.services.title }}</h2>
        </div>
        <div class="row">
            <div class="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="100ms" style="margin-bottom: 30px;" v-for="service in $store.state.services.typeServices" :key="service" >
                <div  class="services-two__single">
                   <div class="services-two__img-box">
                      <div class="services-two__img">
                          <img :src="service.img" alt="{{service.title}}" >
                      </div>
                   </div>
                   <div  class="services-two__content">
                      <h3 class="services-two__title">{{service.title}}</h3>
                   </div>
                </div>
             </div>
        </div>
    </div>
</section>
</template>


<script>


export default {
  name: 'ServiceContent',
}
</script>

<style scoped></style>



