import { createStore } from 'vuex'

export default createStore({
  state: {
    company: {
      name:"Logos Informática",
      adrress: "Rua Barão de Aratanha, 194 - Centro | Fortaleza-CE",
      email: "logosfor@logosfor.com.br",
      openingHours: "Seg à Sex das 08:00 - 18:00",
      whatsApp1: "(85) 99948.1012",
      whatsApp2: "(85) 98761.4228",
      foneFixo1: "(85) 3122.1042",
      foneFixo2: "(85) 3231.5237",
      fonesFixos: "(85) 3122.1042 / 3231.5237",
      whatsApps: "(85) 99948.1012 / 98761.4228",
      map:"#"
    },
    menu: [
      {
        link: "/",
        text: "Home"
      },
      {
        link: "quem-somos",
        text: "Quem Somos"
      },
      {
        link: "produtos",
        text: "Produtos"
      },
      {
        link: "nossos-servicos",
        text: "Nossos Serviços"
      },
      {
        link: "revendedor",
        text: "Seja um revendedor"
      },
      {
        link: "/fale-conosco",
        text: "Contato"
      }
    ],
    banners: [
      {
        subTitle: "Somos Especialistas",
        title1: "Assistência técnica",
        title2: "CHAMA A GENTE!",
        text1: "Assistência técnica especializada em impressoras, CPU´s",
        text2: "monitores de video, Módulos isolador, No-breaks, estabilizadores.",
        buttonLink: "#",
        buttonText: "Orçamento Grátis",
        img: require('@/assets/images/backgrounds/main-slider-2-1.png'),
        shape: require('@/assets/images/shapes/main-slider-two-shape-1.png')
      }
    ],
    aboutUs: {
      tagLine: "Bem-Vindo à",
      title: "Logos Informática",
      subTitle1: "Somos uma empresa que tem se destacado no mercado pelo alto desempenho na manuteção de impressoras, monitores de video, CPU´s, Módulos isolador, No-breaks, estabilizadores. e suprimentos para informática.",
      subTitle2: "Aprimorando e acompanhando a inovação tecnológica constante do mundo moderno, garantindo assim a excelência na solução das necessidades e economia de seus clientes.",
      services1: [
        "Impressoras", "CPU´s", "Monitores de video",
      ],
      services2: [
        "Módulos isolador", "No-breaks", "Estabilizadores",
      ],
      button: {
        text: "SAIBA MAIS",
        link: "#"
      },
      button2: {
        text: "Solicite um Orçamento",
        subText: "(85) 3122.1042",
        link: "#"
      },
    },
    services: {
      tagLine: "Basta Ligar Para Nós",
      title: "NÓS OFERECEMOS UMA AMPLA GAMA DE SERVIÇOS",
      typeServices: [
        {
          img: require("@/assets/images/services/manutencao_impressora.jpg"),
          title: "Manuteção de Impressoras"
        },
        {
          img: require("@/assets/images/services/manutencao_monitor.jpg"),
          title: "Manuteção de Monitores"
        },
        {
          img: require("@/assets/images/services/manutencao_notebook.jpg"),
          title: "Manuteção de computadores"
        },

        {
          img: require("@/assets/images/services/manutencao_nobreak.jpg"),
          title: "Manuteção de no-breaks"
        },
        {
          img: require("@/assets/images/services/manutencao_estabilizador.jpg"),
          title: "Manuteção de módulos isolador e estabilizadores"
        },
        {
          img: require("@/assets/images/services/manutencao_suprimento.jpg"),
          title: "Venda de suprimentos para informática"
        }
      ],
      button: {
        text: "SAIBA MAIS",
        link: "#"
      }
    },
    call: {
      title: "SUA IMPRESSORA QUEBROU? A Logos Informática resolve!",
      subTitle: "Ligue para nós ou fale com a gente pelo nosso Whatsapp, e faremos o melhor orçamento para você em até 24hs."
    },
    testimonials:
    {
      tagLine: "O que nossos Clientes pensam",
      title: "Depoimentos",
      customerTestimonial: [
        {
          logo: require('@/assets/images/testimonial/sertanus.jpeg'),
          company: "Sertanus Tecnologia",
          ceo: "Johnson Rodrigues",
          text: "Encontramos na Logos informática exatamente o que esperávamos de uma empresa dedicada, com análise criteriosa das nossas necessidades combinado com um atendimento personalizado, ágil e eficiente."
        },
        {
          logo: require('@/assets/images/testimonial/testimonial-1-2.jpg'),
          company: "Hempel Corp's",
          ceo: "Gustavo Hempel",
          text: "Pessoal, atendimento nota 10, entrega super rápida e pós vendaexcelente, super recomendo..PARABÉNS A TODA EQUIPE"
        }
      ]
    },
    brand:{
      tagLine: "Melhores marcas do mercado",
      title: "Assistência técnica especializada",
    },
    brands:[
      {
        title:"Microsol",
        img: require('@/assets/images/brand/brand-1-1.png'),
      },
      {
        title:"APC",
        img: require('@/assets/images/brand/brand-1-2.png'),
      },
      {
        title:"HP",
        img: require('@/assets/images/brand/brand-1-3.png'),
      },
      {
        title:"Xerox",
        img: require('@/assets/images/brand/brand-1-4.png'),
      },
      {
        title:"Brother",
        img: require('@/assets/images/brand/brand-1-5.png'),
      },

      // {
      //   title:"Kyocera",
      //   img: require('@/assets/images/brand/brand-1-6.png'),
      // },
      {
        title:"Samsung",
        img: require('@/assets/images/brand/brand-1-7.png'),
      },
      // {
      //   title:"Okidata",
      //   img: require('@/assets/images/brand/brand-1-8.png'),
      // },
      // {
      //   title:"Canon",
      //   img: require('@/assets/images/brand/brand-1-9.png'),
      // },
      // {
      //   title:"Epson",
      //   img: require('@/assets/images/brand/brand-1-10.png'),
      // },
      {
        title:"Lexmark",
        img: require('@/assets/images/brand/brand-1-11.png'),
      },
      {
        title:"SMS",
        img: require('@/assets/images/brand/brand-1-12.png'),
      },
      {
        title:"WEG",
        img: require('@/assets/images/brand/brand-1-13.png'),
      },
      {
        title:"RagTech",
        img: require('@/assets/images/brand/brand-1-14.png'),
      }
    ],
    ContactUs:{
      tagLine: "Dúvidas ou sugestões?",
      title: "Fale Conosco",
    },
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})