<template>
  <section class="message-one">
    <div class="container">
      <div class="section-title text-center">
        <span class="section-title__tagline">Adquira beneficios exclusivos</span>
        <h2 class="section-title__title">Seja um revendedor</h2>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <div class="contact-page-two__right">
            <div v-if="isLoading">
              <p class="text-center">Carregando...</p>
            </div>
            <div v-else>

              <div class="comment-form__btn-box" v-if="showNotFoundMessage">
                <p class="text-center">Dados não encontrados.</p>
                <button type="button" @click="clearInvoiceData" class="thm-btn comment-form__btn">Realizar nova consulta<i
                    class="fa fa-angle-right"></i></button>
              </div>

              <form v-else class="comment-one__form contact-form-validated" novalidate="novalidate"
                    @submit.prevent="submitForm">
                <div class="row">
                  <div class="col-xl-12">
                    <label for="razao_social">Razão Social</label>
                    <div class="comment-form__input-box">
                      <input type="text" placeholder="razao social" name="token" id="razao_social"
                             v-model="razao_social">
                      <span v-if="errors.razao_social" class="error">{{ errors.razao_social }}</span>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <label for="cnpj">CNPJ</label>
                    <div class="comment-form__input-box">
                      <input type="text" placeholder="informe o cnpj" name="cnpj" id="cnpj" v-model="cnpj">
                      <span v-if="errors.cnpj" class="error">{{ errors.cnpj }}</span>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <label for="ie">I.E.</label>
                    <div class="comment-form__input-box">
                      <input type="text" placeholder="informe a inscrição estadual" name="ie" id="ie" v-model="ie">
                      <span v-if="errors.ie" class="error">{{ errors.ie }}</span>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <label for="ie">Endereço</label>
                    <div class="comment-form__input-box">
                      <input type="text" placeholder="informe o endereço" name="endereco" id="endereco"
                             v-model="endereco">
                      <span v-if="errors.endereco" class="error">{{ errors.endereco }}</span>
                    </div>
                  </div>
                  <div class="col-xl-3">
                    <label for="bairro">Bairro</label>
                    <div class="comment-form__input-box">
                      <input type="text" placeholder="informe o bairro" name="bairro" id="bairro" v-model="bairro">
                      <span v-if="errors.bairro" class="error">{{ errors.bairro }}</span>
                    </div>
                  </div>
                  <div class="col-xl-3">
                    <label for="cep">Cep</label>
                    <div class="comment-form__input-box">
                      <input type="text" placeholder="informe o cep" name="cep" id="cep" v-model="cep">
                      <span v-if="errors.cep" class="error">{{ errors.cep }}</span>
                    </div>
                  </div>
                  <div class="col-xl-3">
                    <label for="cidade">Cidade</label>
                    <div class="comment-form__input-box">
                      <input type="text" placeholder="informe a cidade" name="cidade" id="cidade" v-model="cidade">
                      <span v-if="errors.cidade" class="error">{{ errors.cidade }}</span>
                    </div>
                  </div>
                  <div class="col-xl-3">
                    <label for="uf">UF</label>
                    <div class="comment-form__input-box">
                      <input type="text" placeholder="estado" name="uf" id="uf" v-model="uf">
                      <span v-if="errors.uf" class="error">{{ errors.uf }}</span>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <label for="complemento">Complemento</label>
                    <div class="comment-form__input-box">
                      <input type="text" placeholder="informe o complemento" name="complemento" id="complemento"
                             v-model="complemento">
                      <span v-if="errors.complemento" class="error">{{ errors.complemento }}</span>
                    </div>
                  </div>
                  <div class="col-xl-3">
                    <label for="fone_fixo">Fone fixo</label>
                    <div class="comment-form__input-box">
                      <input type="text" placeholder="*********" name="fone_fixo" id="fone_fixo" v-model="fone_fixo">
                      <span v-if="errors.fone_fixo" class="error">{{ errors.fone_fixo }}</span>
                    </div>
                  </div>
                  <div class="col-xl-3">
                    <label for="cel">Cel</label>
                    <div class="comment-form__input-box">
                      <input type="text" placeholder="informe o celular" name="cel" id="cel" v-model="cel">
                      <span v-if="errors.cel" class="error">{{ errors.cel }}</span>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <label for="email">Email</label>
                    <div class="comment-form__input-box">
                      <input type="text" placeholder="*********" name="email" id="email" v-model="email">
                      <span v-if="errors.email" class="error">{{ errors.email }}</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="comment-form__btn-box">
                      <button type="submit" :disabled="isFormInvalid" class="thm-btn comment-form__btn">Realizar
                        Cadastro<i
                            class="fa fa-angle-right"></i></button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";


export default {
  name: "ContactResaleContent",
  data() {
    return {
      invoiceData: null,
      isLoading: false,
      showNotFoundMessage: false,
      razao_social: '',
      cnpj: '',
      ie: '',
      endereco: '',
      bairro: '',
      cep: '',
      cidade: '',
      uf: '',
      complemento: '',
      fone_fixo: '',
      cel: '',
      email: '',
      errors: {}
    };
  },
  computed: {
    isFormInvalid() {
      return this.errors.token || this.errors.password;
    }
  },
  methods: {
    submitForm() {
      this.errors = {};

      if (!this.email) {
        this.errors.email = 'O email é obrigatório.';
      }

      if (!this.razao_social) {
        this.errors.razao_social = 'A razão social é obrigatório.';
      }

      if (!this.cnpj) {
        this.errors.cnpj = 'O CNPJ é obrigatório.';
      }

      if (!this.cel) {
        this.errors.cel = 'O Celular é obrigatório.';
      }

      if (Object.keys(this.errors).length === 0) {
        alert('cadastro enviado com sucesso!!!!');
        this.clearInvoiceData()
      }
    },
    fetchInvoiceData() {
      this.isLoading = true;
      this.showNotFoundMessage = false;
      const formData = new FormData();
      formData.append('token', this.token);
      formData.append('password', this.password);

      axios.post('https://logosfor.com.br/api/orcamento.php', formData)
          .then(response => {
            if (response.data != null) {
              this.invoiceData = response.data;
            } else {
              this.invoiceData = null;
              this.showNotFoundMessage = true;
            }
          })
          .catch(error => {
            console.error(error);
            this.invoiceData = null;
          })
          .finally(() => {
                this.isLoading = false;
              }
          );
    },
    clearInvoiceData() {
      this.invoiceData = null;
      this.razao_social = '';
      this.cnpj = '';
      this.ie = '';
      this.endereco = '';
      this.bairro = '';
      this.cep = '';
      this.cidade = '';
      this.uf = '';
      this.complemento = '';
      this.fone_fixo = '';
      this.cel = '';
      this.email = '';
      this.errors = {};
      this.showNotFoundMessage = false;
    },
  }
};
</script>


<style scoped>

</style>