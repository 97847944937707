<template>
    <nav class="main-menu main-menu-two clearfix">
        <div class="main-menu-two__wrapper clearfix">
            <div class="container">
                <div class="main-menu-two__wrapper-inner clearfix">
                    <div class="main-menu-two__left">
                        <div class="main-menu-two__logo">
                            <a href="/"><img src="@/assets/images/resources/logo-1.png"
                                                      alt="{{$store.company.name}}"></a>
                        </div>
                        <div class="main-menu-two__main-menu-box">
                            <a href="#" class="mobile-nav__toggler"><i class="fa fa-bars"></i></a>
                            <ul class="main-menu__list">
                                <li :class="menu.link === $route.name ?'current':''" v-for="menu in $store.state.menu"
                                    :key="menu.text">
                                  <router-link :to="menu.link">{{ menu.text }}</router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="main-menu-two__right">
                        <div class="main-menu-two__search-call">
                            <div class="main-menu-two__call">
                                <div class="main-menu-two__call-content">
                                    <p class="main-menu-two__call-sub-title">Solicite um orçamento</p>
                                    <h4 class="main-menu-two__call-number"><a href="tel:8532221042"><span class="icon-phone-call"></span>
                                      {{ $store.state.company.fonesFixos }} <br> <br> <i class="fab fa-whatsapp"></i> {{ $store.state.company.whatsApps }}</a></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'ManuHeader',
}
</script>

<style scoped>



</style>







