<template>
  <div>
    <MainHeader/>
    <BreadcrumbContent title="Fale Conosco" page-name="Fale Conosco"/>
    <ContactInfoContent/>
    <ContactContent/>
    <BrandContent/>
    <MainFooter/>
  </div>
</template>

<script>
// @ is an alias to /src


import MainHeader from "@/components/header/MainHeader.vue";
import MainFooter from "@/components/footer/MainFooter.vue";
import BrandContent from "@/components/content/BrandContent.vue";
import BreadcrumbContent from "@/components/content/BreadcrumbContent.vue";
import ContactInfoContent from "@/components/content/ContactInfoContent.vue";
import ContactContent from "@/components/content/ContactContent.vue";


export default {
  name: 'AboutUsView',
  components: {
    ContactContent,
    ContactInfoContent,
    BreadcrumbContent,
    BrandContent,
    MainFooter,
    MainHeader
  }
}
</script>
