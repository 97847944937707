<template>
  <!--Main Slider Start-->
  <section class="main-slider-two clearfix">
    <div class="swiper-container thm-swiper__slider" data-swiper-options='{"slidesPerView": 1, "loop": true,
                "effect": "fade",
                "pagination": {
                "el": "#main-slider-pagination",
                "type": "bullets",
                "clickable": true
                },
                "navigation": {
                "nextEl": "#main-slider__swiper-button-next",
                "prevEl": "#main-slider__swiper-button-prev"
                },
                "autoplay": {
                "delay": 5000
                }}'>
      <div class="swiper-wrapper">

        <div class="swiper-slide" v-for="(banner,index) in $store.state.banners" :key="index">
          <div class="main-slider-two-bg-box">
            <div class="main-slider-two-image-layer" :style="{backgroundImage: 'url('+banner.img+')'}"></div>

            <div class="main-slider-two-shape-box">
              <div class="main-slider-two-shape-1"
                   :style="{backgroundImage: 'url('+banner.shape+')'}">
              </div>
            </div>
          </div>

          <div class="container">
            <div class="row">
              <div class="col-xl-12">
                <div class="main-slider-two__content">
                  <p class="main-slider-two__sub-title">{{banner.subTitle}}</p>
                  <h2 class="main-slider-two__title">{{ banner.title1 }} <br> {{banner.title2}}</h2>
                  <p class="main-slider-two__text">{{banner.text1}} <br> {{banner.text2}} </p>
                  <div class="main-slider-two__btn-box">
                    <a href="{{banner.buttonLink}}" class="thm-btn main-slider-two__btn">{{banner.buttonText}} <i
                        class="fa fa-angle-right"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

<!--      &lt;!&ndash; If we need navigation buttons &ndash;&gt;-->
<!--      <div class="main-slider__nav">-->
<!--        <div class="swiper-button-prev" id="main-slider__swiper-button-next">-->
<!--          <i class="fa fa-angle-left"></i>-->
<!--        </div>-->
<!--        <div class="swiper-button-next" id="main-slider__swiper-button-prev">-->
<!--          <i class="fa fa-angle-right"></i>-->
<!--        </div>-->
<!--      </div>-->

    </div>
  </section>
  <!--Main Slider End-->
</template>


<script>


export default {
  name: 'BannerContent',
}
</script>

<style scoped>

</style>



